import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import { instance } from '../../../../utils/axios';
import getData from '../../../../utils/hooks';
import DateTimePicker from '../../../../components/DatePicker';

export default function DropoffForm() {
  const axios = instance;
  const history = useHistory();
  const [ error, setError ] = useState('');
  const [ captains, setCaptains ] = useState([]);
  const [ sales, setSales ] = useState([]);

  useEffect(() => {
    getData(`${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOSTNAME}:${process.env.REACT_APP_API_PORT}/api/user/classify`, setSales, setError, {
        role: "Sales"
      });
    getData(`${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOSTNAME}:${process.env.REACT_APP_API_PORT}/api/user/classify`, setCaptains, setError);
  }, [])

  const submit = async (values) => {
    try {
      const formRequest = await axios.post(`${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOSTNAME}:${process.env.REACT_APP_API_PORT}/api/dropoff`, {
        ...values,
        captain: Number(values.captain),
        status: 'Active',
        type: "Dropoff"
      });
      if (formRequest.status === 200) {
        history.push('/reports/active')
      }
    } catch (e) {
      setError('Failed to create form. Please try again.')
    }
  }

  const validate = values => {
    const errors = {
      event_datetime: null,
      drop_off_location: null,
      report_name: null,
      arrival_time: null,
      expected_time: null,
      contact: null,
      signature: null,
      captain: null
    };
    if (!values.event_datetime) {
      errors.event_datetime = 'Required';
    }
    if (!values.report_name) {
      errors.report_name = 'Required';
    }
    // if (!values.arrival_time) {
    //   errors.arrival_time = 'Required';
    // }
    // if (!values.expected_time) {
    //   errors.expected_time = 'Required';
    // }
    // if (!values.contact) {
    //   errors.contact = 'Required';
    // }
    // if (!values.signature) {
    //   errors.signature = 'Required';
    // }
    if (!values.captain) {
      errors.captain = 'Required';
    }
    if (!values.drop_off_location) {
      errors.drop_off_location = 'Required';
    }
    return errors;
  };
  const formik = useFormik({
    initialValues: {
      report_name: '',
      event_datetime: '',
      drop_off_location: '',
      arrival_time: '',
      expected_time: '',
      salesperson: '',
      contact: '',
      signature: '',
      captain: ''
    },
    validate,
    onSubmit: (values, {setSubmitting}) => {
      
      submit(values);
    }})
  const { errors, handleSubmit, handleChange, handleBlur, values, setFieldValue, isSubmitting } = formik;
  
  return (
      <form className="space-y-8 divide-y divide-gray-200 mb-8" onSubmit={handleSubmit}>
        <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
          <div className="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
            <div>
              <h3 className="text-lg leading-6 font-medium text-gray-900">Dropoff Event</h3>
            </div>
            <div className="space-y-6 sm:space-y-5">
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label htmlFor="report_name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                  Event Name/Report Name
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <input
                    type="text"
                    name="report_name"
                    id="report_name"
                    autoComplete="event-name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.report_name}
                    className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                  />
                  {/* <div className="inline inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                    <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                  </div> */}
                  {
                    errors.report_name ?
                    <p className="mt-2 text-sm text-red-600" id="event-name-error">
                      Event Name is required.
                    </p>
                  : null
                  }
                </div>
              </div>

              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label htmlFor="event_datetime" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                  Event Date and Time
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <DateTimePicker
                    id="event_datetime"
                    name="event_datetime"
                    value={values.event_datetime}
                    defaultVaue="event-date"
                    interval={15}
                    onChange={setFieldValue}
                  />
                  {
                    errors.event_datetime ?
                    <p className="mt-2 text-sm text-red-600" id="event-date-error">
                      Event Date is required.
                    </p>
                  : null
                  }
                </div>
              </div>

              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label htmlFor="event_datetime" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                  Drop Off Location
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <input
                    type="text"
                    name="drop_off_location"
                    id="drop_off_location"
                    autoComplete="drop-off-location"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.drop_off_location}
                    className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                  />
                  {
                    errors.event_datetime ?
                    <p className="mt-2 text-sm text-red-600" id="event-date-error">
                      Drop off location is required.
                    </p>
                  : null
                  }
                </div>
              </div>

              {/* <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label htmlFor="arrival_time" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                  Arrival Time
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <input
                    id="arrival_time"
                    name="arrival_time"
                    type="arrival_time"
                    autoComplete="arrival-time"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.arrival_time}
                    className="block max-w-lg w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
                  />
                  {
                    errors.arrival_time ?
                    <p className="mt-2 text-sm text-red-600" id="arrival-time-error">
                      Arrival Time is required.
                    </p>
                  : null
                  }
                </div>
              </div>
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label htmlFor="expected_time" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                  Expected Time
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <input
                    type="text"
                    name="expected_time"
                    id="expected_time"
                    autoComplete="expected_time"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.expected_time}
                    className="block max-w-lg w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
                  />
                  {
                    errors.expected_time ?
                    <p className="mt-2 text-sm text-red-600" id="expected-time-error">
                      Expected Time is required.
                    </p>
                  : null
                  }
                </div>
              </div>

              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label htmlFor="contact" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                  Contact
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <input
                    type="text"
                    name="contact"
                    id="contact"
                    autoComplete="contact"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.contact}
                    className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                  />
                  {
                    errors.contact ?
                    <p className="mt-2 text-sm text-red-600" id="contact-error">
                      Contact is required.
                    </p>
                  : null
                  }
                </div>
              </div>

              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label htmlFor="signature" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                  Signature
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <input
                    type="text"
                    name="signature"
                    id="signature"
                    className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                  />
                  {
                    errors.signature ?
                    <p className="mt-2 text-sm text-red-600" id="event-name-error">
                      Event Name is required by final submission
                    </p>
                  : null
                  }
                </div>
              </div> */}
            </div>
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="salesperson" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Salesperson
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <select
                  id="salesperson"
                  value={values.salesperson}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="salesperson"
                  autoComplete="salesperson"
                  className="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                >
                  <option></option>
                  {sales && sales.length > 0 && sales.map((sale) => {
                    return (
                      <option value={sale.id} id={sale.id}>{sale.first_name} {sale.last_name}</option>
                    )
                  })}
                </select>
              </div>
            </div>
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label htmlFor="captain" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                  Assign to
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <select
                    id="captain"
                    name="captain"
                    autoComplete="captain"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.captain}
                    className="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                  >
                    <option></option>
                    {captains && captains.map((captain) => {
                      return (
                        <option key={captain.uuid} value={captain.id}>{captain.first_name} {captain.last_name}</option>
                      )
                    })}
                  </select>
                  {
                    errors.captain ?
                    <p className="mt-2 text-sm text-red-600" id="event-name-error">
                      Captain is required.
                    </p>
                  : null
                  }
                </div>
              </div>
            </div>
          </div>

        <div className="pt-5">
          <div className="flex justify-end">
            <Link to="/reports">
              <button
                type="button"
                className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Cancel
              </button>
            </Link>
            <button
              disabled={isSubmitting}
              type="submit"
              onClick={() => {submit(values)}}
              className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Save
            </button>
            { error &&
              <div className="text-sm font-medium text-red-700">{error}</div>
            }
          </div>
        </div>
      </form>
  )
}
