import { instance } from './axios';

const axios = instance;

const getData = async (url, setVal, setE, where) => {
    try {
      const formRequest = await axios.post(url, {
        where: where || {
          "role": "Captain"
        }
      });
      if (formRequest.status === 200) {
        setVal(formRequest.data);
      }
    } catch (e) {
      setE('Failed to create form. Please try again.')
    }
  }

export default getData;