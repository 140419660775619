const makeArrayOfTime = (startTime, interval) => {
    const x = interval; //minutes interval
    const times = []; // time array
    let tt = startTime; // start time
    const ap = ['AM', 'PM']; // AM-PM
    for (let i=0;tt<24*60; i++) {
        const hh = Math.floor(tt/60); // getting hours of day in 0-24 format
        const mm = (tt%60); // getting minutes of the hour in 0-55 format
        times[i] = {id: i, name: ("0" + (hh % 12)).slice(-2) + ':' + ("0" + mm).slice(-2) + ap[Math.floor(hh/12)]}; // pushing data in array in [00:00 - 12:00 AM/PM format]
        tt = tt + x;
    }
    return times;
}

export const businessHours = makeArrayOfTime(360, 15);

export default makeArrayOfTime;
