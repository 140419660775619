import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import { instance } from '../../utils/axios';
import { addUpload } from '../../utils/s3';

export default function Dev() {
  const axios = instance;
  const history = useHistory();
  const [ error, setError ] = useState('');
  const [ reports, setReports ] = useState({});
  const split_path = window.location.pathname.split('/');
  const guuid = split_path[4];
    
    useEffect(() => {
      axios.get(`${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOSTNAME}:${process.env.REACT_APP_API_PORT}/api/dropoff/${guuid}`).then((data) => {
        setReports(data.data)
      })
    }, [])

  const submit = async (values) => {
    try {
      const formRequest = await axios.put(`${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOSTNAME}:${process.env.REACT_APP_API_PORT}/api/dropoff/${guuid}`, {
        ...values,
        status: 'Complete'
      });
      if (formRequest.status === 200) {
        history.push('/reports/past')
      }
    } catch (e) {
      setError('Failed to submit form. Please try again.')
    }
  }

  const handleUpload = async (guuid, element) => {
    const upload = await addUpload(guuid, element)
  }

  return (
      <div className="space-y-8 divide-y divide-gray-200 mb-8">
        <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
          

              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label htmlFor="uploads" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                  Uploads
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <input
                    type="file"
                    name="uploads"
                    multiple
                    id="uploads"
                    capture
                    autoComplete="uploads"
                    // value={values.uploads}
                    className="block max-w-lg w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
                <button onClick={() => {handleUpload(guuid, document.getElementById('uploads'))}}>
                  Upload file
                </button>
              </div>
        </div>

           
        <div className="pt-5">
          <div className="flex justify-end">
            <Link to="/reports">
              <button
                type="button"
                className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Cancel
              </button>
            </Link>
            <button
              className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Save
            </button>
            { error &&
              <div className="text-sm font-medium text-red-700">{error}</div>
            }
          </div>
        </div>
      </div>
  )
}
