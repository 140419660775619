import React, { useState, useContext, createContext } from 'react';

const authContext = createContext();

const useAuth = () => {
  return useContext(authContext);
}

const fakeAuth = {
  isAuthenticated: false,
  signin(cb) {
    fakeAuth.isAuthenticated = true;
    setTimeout(cb, 100); // fake async
  },
  signout(cb) {
    fakeAuth.isAuthenticated = false;
    setTimeout(cb, 100);
  }
};

export const useProvideAuth = () => {
  const [user, setUser] = useState(null);

  const signin = (token, cb) => {
    return fakeAuth.signin(() => {
      setUser(token);
      cb();
    });
  };

  const signout = cb => {
    return fakeAuth.signout(() => {
      setUser(null);
      cb();
    });
  };

  return {
    user,
    signin,
    signout
  };
}

export const ProvideAuth = ({ children }) => {
  const auth = useProvideAuth();
  return (
    <authContext.Provider value={auth}>
      {children}
    </authContext.Provider>
  );
}

export default useAuth;