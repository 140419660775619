import React, { useEffect } from 'react';
import { 
  Switch,
  Route,
  BrowserRouter as Router } from 'react-router-dom';
import Dashboard from './containers/Dashboard';
import SideNav from './containers/SideNav';
import Reports from './containers/Reports';
import ContentArea from './containers/ContentArea';
import Login from './containers/Login';
import PrivateRoute from './utils/privateRoute';
import { ProvideAuth } from './utils/auth';
import Dev from './containers/Dev';

function App() {
  const routes = {
    '': 'Dashboard',
    '/login': 'Login',
    '/reports': 'Reports',
    '/reports?past=true': 'Past Reports'
  }

  let path;
  const token = localStorage.getItem('access_token')

  useEffect(() => {
    path = routes[window.location.pathname];
  }, [window.location, token])

  return (
    <div>
      <ProvideAuth>
        <Router>
          <Switch>
            <Route exact path="/login" component={Login} />
            <SideNav>
              <ContentArea page_title={path}>
                <PrivateRoute>
                  <Route exact path="/" component={Dashboard} />
                </PrivateRoute>
                <PrivateRoute>
                  <Route path="/reports" component={Reports} />
                </PrivateRoute>
                <PrivateRoute>
                  <Route path="/dev" component={Dev} />
                </PrivateRoute>
              </ContentArea>
            </SideNav>
          </Switch>
        </Router>
      </ProvideAuth>
    </div>
  )
}

export default App;
