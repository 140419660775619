import React, { useEffect, useState, useRef } from 'react';
import SignatureCanvas from 'react-signature-canvas'
import { Link, useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import { instance } from '../../../../utils/axios';
import Toggle from '../../../../components/Toggle';
import getData from '../../../../utils/hooks';
import initialValues from '../../Create/Standard/initialValues';
import toggleValues from '../../Create/Standard/toggleValues';
import { addUpload, addUploadByString } from '../../../../utils/s3';
import DateTimePicker from '../../../../components/DatePicker';


export default function ActiveStandard() {
    const axios = instance;
    const [toggled, toggleButton] = useState(toggleValues);
    const history = useHistory();
    const [ error, setError ] = useState('');
    const [ captains, setCaptains ] = useState([]);
    const [ reports, setReports ] = useState({});
    const split_path = window.location.pathname.split('/');
    const guuid = split_path[4];
    const [ sales, setSales ] = useState([]);
    let sigpad = useRef<HTMLCanvasElement>();
    useEffect(() => {
      getData(`${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOSTNAME}:${process.env.REACT_APP_API_PORT}/api/user/classify`, setSales, setError, {
          role: "Sales"
        });
      getData(`${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOSTNAME}:${process.env.REACT_APP_API_PORT}/api/user/classify`, setCaptains, setError);
    }, [])

    const handleUpload = async (guuid, element, e) => {
      if (e) {
        e.preventDefault();
      }
      const upload = await addUpload(guuid, element)
      return upload;
    }

    const handleToggleButton = (name, value) => {

      toggleButton({
        ...toggled,
        [name]: !toggled[name]
      })
    }
    const submit = async (values) => {
      const combined = {...values, ...toggled}
      await addUploadByString(guuid, sigpad.current.toDataURL('image/png'))
      
      try {
        const formRequest = await axios.put(`${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOSTNAME}:${process.env.REACT_APP_API_PORT}/api/standard/${guuid}`, {
          ...combined,
          status: 'Complete',
          type: "Standard EFS Form",
        });
        if (formRequest.status === 200) {
          history.push('/reports/past')
        }
      } catch (e) {
        console.error(e)
        setError('Failed to create form. Please try again.')
      }
    }

    const validate = values => {
      const errors = {
      };

      return errors;
    };

    useEffect(() => {
      getData(`${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOSTNAME}:${process.env.REACT_APP_API_PORT}/api/user/classify`, setCaptains, setError);
    }, [])

    useEffect(() => {
      axios.get(`${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOSTNAME}:${process.env.REACT_APP_API_PORT}/api/standard/${guuid}`).then((data) => {
        setReports(data.data)
      })
    }, [])
    
    const formik = useFormik({
      initialValues: reports,
      validate,
      enableReinitialize: true,
      onSubmit: (values, {setSubmitting}) => {
        submit(values);
      }})
    const { errors, handleSubmit, handleChange, handleBlur, setFieldValue, values, isSubmitting } = formik;
      
    return (
      <form className="space-y-8 divide-y divide-gray-200 mb-8" onSubmit={handleSubmit}>
        <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
          <div className="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
            <div>
              <h3 className="text-lg leading-6 font-medium text-gray-900">Standard EFS Event</h3>
            </div>
            <div className="space-y-6 sm:space-y-5">
              <h4 className="text-lg leading-6 font-medium text-gray-900">Event Information</h4>
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="beo_number" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                BEO #
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  type="text"
                  value={values.beo_number}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="beo_number"
                  id="beo_number"
                  autoComplete="beo-number"
                  className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                />
                </div>
              </div>
  
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label htmlFor="event_datetime" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                  Event Date
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                <DateTimePicker
                  id="event_datetime"
                  name="event_datetime"
                  value={values.event_datetime}
                  defaultVaue="event-date"
                  interval={15}
                  onChange={setFieldValue}
                />
                </div>
              </div>

              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label htmlFor="event_location" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                  Venue
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <input
                    id="event_location"
                    value={values.event_location}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="event_location"
                    type="event_location"
                    autoComplete="event_location"
                    className="block max-w-lg w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>

              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label htmlFor="planner" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                  Planner
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <input
                    id="planner"
                    value={values.planner}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="planner"
                    type="planner"
                    autoComplete="planner"
                    className="block max-w-lg w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>

              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label htmlFor="salesperson" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                  Salesperson
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <select
                    id="salesperson"
                    value={values.salesperson}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="salesperson"
                    autoComplete="salesperson"
                    className="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                  >
                    <option></option>
                    <option></option>
                    {sales && sales.length > 0 && sales.map((sale) => {
                      return (
                        <option value={sale.id} id={sale.id}>{sale.first_name} {sale.last_name}</option>
                      )
                    })}
                  </select>
                </div>
              </div>

              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label htmlFor="captain" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                  Assign to captain
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <select
                    id="captain"
                    name="captain"
                    autoComplete="captain"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.captain}
                    className="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                  >
                    <option></option>
                    {captains && captains.map((captain) => {
                      return (
                        <option key={captain.uuid} value={captain.id}>{captain.first_name} {captain.last_name}</option>
                      )
                    })}
                  </select>
                  {
                    errors.captain ?
                    <p className="mt-2 text-sm text-red-600" id="event-name-error">
                      Captain is required.
                    </p>
                  : null
                  }
                </div>
              </div>

              <h4 className="text-lg leading-6 font-medium text-gray-900">Activity</h4>

              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label htmlFor="arrival_time" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                  Arrival Time
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <input
                    id="arrival_time"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.arrival_time}
                    name="arrival_time"
                    type="arrival_time"
                    autoComplete="arrival_time"
                    className="block max-w-lg w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>

              <h4 className="text-lg leading-6 font-medium text-gray-900">Setup</h4>

              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label htmlFor="event_setup_on_time" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                  On Timeline?
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <Toggle
                    id="event_setup_on_time"
                    value={values.event_setup_on_time}
                    toggled={toggled['event_setup_on_time']}
                    handleToggleButton={handleToggleButton}
                    />
                    { !toggled['event_setup_on_time'] && <>
                      <span className="text-sm ml-8 font-medium text-gray-900">Why not?</span>
                      <input
                        type="text"
                        value={values.event_setup_on_timeline_reason}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="setup_on_timeline_reason"
                        id="setup_on_timeline_reason"
                        autoComplete="setup-on-timeline"
                        className="max-w-md ml-8 w-small shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
                      />
                      </>
                    }
                  </div>
              </div>

              <h4 className="text-lg leading-6 font-medium text-gray-900">Cocktail Hour</h4>

              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label htmlFor="cocktail_hour_on_time" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                  On Timeline?
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <Toggle
                    id="cocktail_hour_on_time"
                    value={values.cocktail_hour_on_time}
                    toggled={toggled['cocktail_hour_on_time']}
                    onChange={v => {
                      setFieldValue('cocktail_hour_on_time', v.target.value);
                    }}
                    handleToggleButton={handleToggleButton}
                    />
                    { !toggled['cocktail_hour_on_time'] && <>
                      <span className="text-sm ml-8 font-medium text-gray-900">Why not?</span>
                      <input
                        type="text"
                        value={values.cocktail_hour_on_time_reason}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="cocktail_hour_on_time_reason"
                        id="cocktail_hour_on_time_reason"
                        autoComplete="cocktail-on-timeline-reason"
                        className="max-w-md ml-8 w-small shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
                      />
                      </>
                    }
                  </div>
              </div>

              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label htmlFor="set_side_for_bg" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                  Set aside for B&G?
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <Toggle
                    id="set_side_for_bg"
                    toggled={toggled['set_side_for_bg']}
                    onChange={v => {
                      setFieldValue('set_side_for_bg', v.target.value);
                    }}
                    handleToggleButton={handleToggleButton}
                    />
                    { !toggled['set_side_for_bg'] && <>
                      <span className="text-sm ml-8 font-medium text-gray-900">Why not?</span>
                      <input
                        type="text"
                        value={values.set_side_for_bg_reason}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="set_side_for_bg_reason"
                        id="set_side_for_bg_reason"
                        autoComplete="set-side-for-bg-reason"
                        className="max-w-md ml-8 w-small shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
                      />
                      </>
                    }
                  </div>
              </div>

              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label htmlFor="room_setup_in_time" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                  Room set up in time?
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <Toggle
                    id="room_setup_in_time"
                    toggled={toggled['room_setup_in_time']}
                    onChange={v => {
                      setFieldValue('room_setup_in_time', v.target.value);
                    }}
                    handleToggleButton={handleToggleButton}
                    />
                    { !toggled['room_setup_in_time'] && <>
                      <span className="text-sm ml-8 font-medium text-gray-900">Why not?</span>
                      <input
                        type="text"
                        value={values.room_setup_in_time_reason}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="room_setup_in_time_reason"
                        id="room_setup_in_time_reason"
                        autoComplete="room-setup-in-time-reason"
                        className="max-w-md ml-8 w-small shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
                      />
                      </>
                    }
                  </div>
              </div>

              <h4 className="text-lg leading-6 font-medium text-gray-900">Dinner</h4>

              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label htmlFor="vendors_served" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                  Vendors served?
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <Toggle
                    id="vendors_served"
                    toggled={toggled['vendors_served']}
                    onChange={v => {
                      setFieldValue('vendors_served', v.target.value);
                    }}
                    handleToggleButton={handleToggleButton}
                    />
                    { !toggled['vendors_served'] && <>
                      <span className="text-sm ml-8 font-medium text-gray-900">Why not?</span>
                      <input
                        type="text"
                        value={values.vendors_served_reason}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="vendors_served_reason"
                        id="vendors_served_reason"
                        autoComplete="vendors-served-reason"
                        className="max-w-md ml-8 w-small shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
                      />
                      </>
                    }
                  </div>
              </div>

              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label htmlFor="bg_served" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                  B&G served?
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <Toggle
                    id="bg_served"
                    toggled={toggled['bg_served']}
                    onChange={v => {
                      setFieldValue('bg_served', v.target.value);
                    }}
                    handleToggleButton={handleToggleButton}
                    />
                    { !toggled['bg_served'] && <>
                      <span className="text-sm ml-8 font-medium text-gray-900">Why not?</span>
                      <input
                        type="text"
                        value={values.bg_served_reason}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="bg_served_reason"
                        id="bg_served_reason"
                        autoComplete="cocktail-on-timeline-reason"
                        className="max-w-md ml-8 w-small shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
                      />
                      </>
                    }
                  </div>
              </div>

              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label htmlFor="diet_restrictions_served" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                  Dietary restrictions served?
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <Toggle
                    id="diet_restrictions_served"
                    toggled={toggled['diet_restrictions_served']}
                    handleToggleButton={handleToggleButton}
                    />
                    { !toggled['diet_restrictions_served'] && <>
                      <span className="text-sm ml-8 font-medium text-gray-900">Why not?</span>
                      <input
                        type="text"
                        value={values.dietary_restrictions_reason}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="dietary_restrictions_reason"
                        id="dietary_restrictions_reason"
                        autoComplete="cocktail-on-timeline-reason"
                        className="max-w-md ml-8 w-small shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
                      />
                      </>
                    }
                  </div>
              </div>

              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label htmlFor="extra_food_boxed_bg" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                  Extra food boxed for B&G?
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <Toggle
                    id="extra_food_boxed_bg"
                    onChange={v => {
                      setFieldValue('extra_food_boxed_bg', v.target.value);
                    }}
                    toggled={toggled['extra_food_boxed_bg']}
                    handleToggleButton={handleToggleButton}
                    />
                    { !toggled['extra_food_boxed_bg'] && <>
                      <span className="text-sm ml-8 font-medium text-gray-900">Why not?</span>
                      <input
                        type="text"
                        value={values.extra_food_boxed_bg_reason}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="extra_food_boxed_bg_reason"
                        id="extra_food_boxed_bg_reason"
                        autoComplete="cocktail-on-timeline-reason"
                        className="max-w-md ml-8 w-small shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
                      />
                      </>
                    }
                  </div>
              </div>

              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label htmlFor="dinner_on_time" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                  On Timeline?
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <Toggle
                    id="dinner_on_time"
                    onChange={v => {
                      setFieldValue('dinner_on_time', v.target.value);
                    }}
                    toggled={toggled['dinner_on_time']}
                    handleToggleButton={handleToggleButton}
                    />
                    { !toggled['dinner_on_time'] && <>
                      <span className="text-sm ml-8 font-medium text-gray-900">Why not?</span>
                      <input
                        type="text"
                        value={values.dinner_on_time}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="dinner_on_time"
                        id="dinner_on_time"
                        autoComplete="dinner-on-time"
                        className="max-w-md ml-8 w-small shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
                      />
                      </>
                    }
                  </div>
              </div>

              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label htmlFor="number_of_people_served_dinner" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                  Total number of people served
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <input
                    type="text"
                    value={values.number_of_people_served_dinner}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="number_of_people_served_dinner"
                    id="number_of_people_served_dinner"
                    autoComplete="num-of-people-served"
                    className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>

              <h4 className="text-lg leading-6 font-medium text-gray-900">Cake</h4>

              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label htmlFor="cake_topper_kept" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                  Cake topper kept?
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <Toggle
                    id="cake_topper_kept"
                    onChange={v => {
                      setFieldValue('cake_topper_kept', v.target.value);
                    }}
                    toggled={toggled['cake_topper_kept']}
                    handleToggleButton={handleToggleButton}
                    />
                    { !toggled['cake_topper_kept'] && <>
                      <span className="text-sm ml-8 font-medium text-gray-900">Why not?</span>
                      <input
                        type="text"
                        value={values.cake_topper_kept_reason}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="cake_topper_kept_reason"
                        id="cake_topper_kept_reason"
                        autoComplete="cocktail-on-timeline-reason"
                        className="max-w-md ml-8 w-small shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
                      />
                      </>
                    }
                  </div>
              </div>
              
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label htmlFor="cake_boxed_bg" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                  Cake boxed for B&G?
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <Toggle
                    id="cake_boxed_bg"
                    onChange={v => {
                      setFieldValue('cake_boxed_bg', v.target.value);
                    }}
                    toggled={toggled['cake_boxed_bg']}
                    handleToggleButton={handleToggleButton}
                    />
                    { !toggled['cake_boxed_bg'] && <>
                      <span className="text-sm ml-8 font-medium text-gray-900">Why not?</span>
                      <input
                        type="text"
                        value={values.cake_boxed_bg_reason}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="cake_boxed_bg_reason"
                        id="cake_boxed_bg_reason"
                        autoComplete="cake-boxed-bg-reason"
                        className="max-w-md ml-8 w-small shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
                      />
                      </>
                    }
                  </div>
              </div>

              <h4 className="text-lg leading-6 font-medium text-gray-900">End of event</h4>

              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label htmlFor="food_given_to_planner" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                  Food/cake given to planner?
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <Toggle
                    id="food_given_to_planner"
                    onChange={v => {
                      setFieldValue('food_given_to_planner', v.target.value);
                    }}
                    toggled={toggled['food_given_to_planner']}
                    handleToggleButton={handleToggleButton}
                    />
                    { !toggled['food_given_to_planner'] && <>
                      <span className="text-sm ml-8 font-medium text-gray-900">Why not?</span>
                      <input
                        type="text"
                        value={values.food_given_to_planner_reason}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="food_given_to_planner_reason"
                        id="food_given_to_planner_reason"
                        autoComplete="cocktail-on-timeline-reason"
                        className="max-w-md ml-8 w-small shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
                      />
                      </>
                    }
                  </div>
              </div>

              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label htmlFor="equipment_kitchen_clean" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                  Kitchen / Equipment clean?
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <Toggle
                    id="equipment_kitchen_clean"
                    onChange={v => {
                      setFieldValue('equipment_kitchen_clean', v.target.value);
                    }}
                    toggled={toggled['equipment_kitchen_clean']}
                    handleToggleButton={handleToggleButton}
                    />
                    { !toggled['equipment_kitchen_clean'] && <>
                      <span className="text-sm ml-8 font-medium text-gray-900">Why not?</span>
                      <input
                        type="text"
                        value={values.equipment_kitchen_clean_reason}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="equipment_kitchen_clean_reason"
                        id="equipment_kitchen_clean_reason"
                        autoComplete="cocktail-on-timeline-reason"
                        className="max-w-md ml-8 w-small shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
                      />
                      </>
                    }
                  </div>
              </div>

              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label htmlFor="check_out_on_time" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                  Check out on time?
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <Toggle
                    id="check_out_on_time"
                    onChange={v => {
                      setFieldValue('check_out_on_time', v.target.value);
                    }}
                    toggled={toggled['check_out_on_time']}
                    handleToggleButton={handleToggleButton}
                    />
                    { !toggled['check_out_on_time'] && <>
                      <span className="text-sm ml-8 font-medium text-gray-900">Why not?</span>
                      <input
                        type="text"
                        value={values.check_out_on_time_reason}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="check_out_on_time_reason"
                        id="check_out_on_time_reason"
                        autoComplete="cocktail-on-timeline-reason"
                        className="max-w-md ml-8 w-small shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
                      />
                      </>
                    }
                  </div>
              </div>

              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label htmlFor="check_out_with" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                  Checked out with who?
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <input
                    type="text"
                    value={values.check_out_with}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="check_out_with"
                    id="check_out_with"
                    autoComplete="check-out-name"
                    className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>

              <h4 className="text-lg leading-6 font-medium text-gray-900">Event summary (please be detailed)</h4>
  
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label htmlFor="event_summary" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                  Summary
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <textarea
                    rows={10}
                    value={values.event_summary}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="event_summary"
                    id="event_summary"
                    className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label htmlFor="uploads" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                  Uploads
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <input
                    type="file"
                    name="uploads"
                    id="uploads"
                    multiple
                    capture
                    autoComplete="uploads"
                    // onChange={handleChange}
                    onBlur={handleBlur}
                    // value={values.uploads}
                    className="block max-w-lg w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
                <button onClick={(e) => {handleUpload(guuid, document.getElementById('uploads'), e)}}>
                  Upload file
                </button>
              </div>
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="client-signature" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Client Signature
              </label>
              <SignatureCanvas
                penColor='green'
                ref={sigpad}
                id="signature"
                canvasProps={{width: 500, height: 200, className: 'sigCanvas bg-white'}} />
            </div>
            </div>
            </div>
            
          </div>
  
        <div className="pt-5">
          <div className="flex justify-end">
            <Link to="/reports">
              <button
                type="button"
                className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Cancel
              </button>
            </Link>
            <button
              type="submit"
              onClick={() => {submit(values)}}
              className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
            Save
          </button>
          </div>
        </div>
      </form>
    )
  }
  