import React, { useState } from "react";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

// CSS Modules, react-datepicker-cssmodules.css
// import 'react-datepicker/dist/react-datepicker-cssmodules.css';

const DateTimePicker = (props) => {
    const { name, value, onChange, interval } = props
  return (
    <DatePicker
        selected={(value && new Date(value)) || null}
        showTimeSelect
        dateFormat="Pp"
        timeIntervals={interval}
        onChange={val => {
            onChange(name, val);
        }}
    />
  );
};

export default DateTimePicker;
