const axios = require('axios').default;

const instance = axios.create({})
instance.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
instance.defaults.headers.put['Content-Type'] = 'application/x-www-form-urlencoded';
const createHeaders = (AUTH_TOKEN) => {
    instance.defaults.headers.common['Authorization'] = AUTH_TOKEN;
}

export { instance, createHeaders };
