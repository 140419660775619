const initialToggleValues = {
    event_started_on_time: false,
    number_of_people_accurate: false,
    enough_food: false,
    extra_food_boxed: false,
    outside_food: false,
    outside_alcohol: false,
    event_setup_on_time: false,
    equipment_needed: false,
    clean_venue_afterwards: false,
    incidents_occurred: false,
    delivery_on_time: false,
    clients_on_time: false,
    is_delivery: false,
    delivery_issues: false
}

export default initialToggleValues;
