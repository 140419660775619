import React from 'react'
import ActiveDropoff from './Active/Dropoff';
import ActiveHG from './Active/HG';
import ActiveStandard from './Active/Standard';
import DropoffForm from './Create/Dropoff';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import StandardForm from './Create/Standard';
import HGForm from './Create/HG';
import ActiveReports from './Active';
import PastDetail from './Past/Detail';
import PastReports from './Past';
import Create from './Create';

export default function Reports() {
  let { path, url } = useRouteMatch();
  
  return (
    <div className="h-screen bg-gray-100">
      {window.location.pathname === '/reports' ? <Create /> : null } 
      <Switch>
        <Route exact path={`${url}/dropoff`} component={DropoffForm} />
        <Route exact path={`${url}/standard`} component={StandardForm} />
        <Route exact path={`${url}/hgform`} component={HGForm} />
        <Route exact path={`${url}/past`} component={PastReports} />
        <Route exact path={`${url}/active`} component={ActiveReports} />
        <Route exact path={`${url}/view/dropoff/:guuid`} component={ActiveDropoff} />
        <Route exact path={`${url}/view/hg-order-form/:guuid`} component={ActiveHG} />
        <Route exact path={`${url}/view/standard-efs-form/:guuid`} component={ActiveStandard} />
        <Route exact path={`${url}/past/view/:type/:guuid`} component={PastDetail} />
      </Switch>
    </div>
  )
};