import { S3Client, S3, PutObjectCommand,GetObjectCommand, GetObjectAclRequest, ListObjectsCommand, DeleteObjectCommand, DeleteObjectsCommand } from '@aws-sdk/client-s3';
import { getSignedUrl } from '@aws-sdk/s3-request-presigner';

const credentials = {
  accessKeyId: 'AKIAXKMWGBFUWOT2Z26H',
  secretAccessKey: '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',
}
export const client = new S3({region: 'us-east-1', credentials});
client.config.credentials = credentials;
const bucketParams = {
    Bucket : 'efscaptains'
};

console.log('++++++++ initialized client', client)


export const listUploadsByDirectory = async (directory) => {
  let data;
  try {
    data = await client.send(
      new ListObjectsCommand({ Prefix: directory, Bucket: 'efscaptains' })
      );
    } catch (e) {
      data = e;
    }
    return data;
  };
  
  export const getContentsById = async (id) => {
    let data;
    const command = new GetObjectCommand({ Key: id, Bucket: 'efscaptains' });
    // try {
      //   data = await client.send(
        //       new GetObjectCommand({ Key: id, Bucket: 'efscaptains' })
        //   );
        // } catch (e) {
          //     data = e;
          // }
          // return data;
          const url = await getSignedUrl(client, command, { expiresIn: 3600 })
          //cosnsole.log('url:', url);
          return url;
        };
        
        export const listUploads = async () => {
          let data;
          try {
            data = await client.send(
              new ListObjectsCommand({ Delimiter: "/", Bucket: 'efscaptains' })
              );
            } catch (e) {
              data = e;
            }
            return data;
          };
          export const addUploadByString = async (formGuuid, string) => {
            const albumPhotosKey = encodeURIComponent(formGuuid) + "/";
            const file = string;
      let buf = Buffer.from(file.replace(/^data:image\/\w+;base64,/, ""),'base64')
      const fileName = 'client-signature.png'
      const uploadParams = {
        Bucket: 'efscaptains',
        Key: albumPhotosKey + fileName,
        Body: buf,
        ContentEncoding: 'base64',
        ContentType: 'image/png'
      }
      let doc;
      try {
        doc = await client.send(new PutObjectCommand(uploadParams));
      } catch (err) {
        doc = {
          name: 'Error'
        }
        return alert("There was an error uploading: ", err.message);
      }
      return doc
    }

export const addUpload = async (formGuuid, element) => {
    const files = element.files;
    console.log('found files during upload: ', files)
    try {
      const albumPhotosKey = encodeURIComponent(formGuuid) + "/";
      for (let index = 0; index < files.length; index++) {
        const file = files[index];
        const fileName = file.name;
        const uploadParams = {
          Bucket: 'efscaptains',
          Key: albumPhotosKey + fileName,
          Body: file
        };
        try {
          await client.send(new PutObjectCommand(uploadParams));
          alert("Successfully uploaded.");
        } catch (err) {
          return alert("There was an error uploading: ", err.message);
        }}
    } catch (err) {
    if (!files.length) {
        return alert("Choose a file to upload first.");
      }
    }
}

export default client
