import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import { instance } from '../../../../utils/axios';
import Toggle from '../../../../components/Toggle';
import getData from '../../../../utils/hooks';
import initialValues from './initialValues';
import toggleValues from './toggleValues';
import DateTimePicker from '../../../../components/DatePicker';
import locations from './hglocations';
import { businessHours } from '../../../../utils/time';

export default function HGForm() {
  const axios = instance;
  const [toggled, toggleButton] = useState(toggleValues);
  const history = useHistory();
  const [ error, setError ] = useState('');
  const [ captains, setCaptains ] = useState([]);
  const [ sales, setSales ] = useState([]);

  useEffect(() => {
    getData(`${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOSTNAME}:${process.env.REACT_APP_API_PORT}/api/user/classify`, setSales, setError, {
        role: "Sales"
      });
    getData(`${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOSTNAME}:${process.env.REACT_APP_API_PORT}/api/user/classify`, setCaptains, setError);
  }, [])
  const handleToggleButton = (name, value) => {

    toggleButton({
      ...toggled,
      [name]: !toggled[name]
    })
  }
  const submit = async (values) => {
    const combined = {...values, ...toggled}

    try {
      const formRequest = await axios.post(`${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOSTNAME}:${process.env.REACT_APP_API_PORT}/api/hgform`, {
        ...combined,
        assigned_to: Number(combined.captain),
        captain: Number(combined.captain),
        beo_number: combined.beo_number,
        salesperson: Number(combined.salesperson),
        number_of_people: Number(combined.number_of_people),
        created_by: 1,
        status: 'Active',
        type: "HG Order Form",
      });
      if (formRequest.status === 200) {
        history.push('/reports/active')
      }
    } catch (e) {
      setError('Failed to create form. Please try again.')
    }
  }

  const validate = values => {
    const errors = {
    };

    return errors;
  };
  const formik = useFormik({
    initialValues,
    validate,
    onSubmit: (values, {setSubmitting}) => {
      submit(values);
    }})
  const { errors, handleSubmit, handleChange, handleBlur, setFieldValue, values, isSubmitting } = formik;
  
    return (
      <form className="space-y-8 divide-y divide-gray-200 mb-8" onSubmit={handleSubmit}>
      <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
        <div className="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">HG Order</h3>
          </div>
          <div className="space-y-6 sm:space-y-5">
            <h4 className="text-lg leading-6 font-medium text-gray-900">Event Information</h4>
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="beo_number" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                BEO #
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  type="text"
                  value={values.beo_number}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="beo_number"
                  id="beo_number"
                  autoComplete="beo-number"
                  className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                />
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="event_datetime" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Event Date
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
              <DateTimePicker
                  id="event_datetime"
                  name="event_datetime"
                  value={values.event_datetime}
                  defaultVaue="event-date"
                  interval={15}
                  onChange={setFieldValue}
                />
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label htmlFor="event_location" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                  Location
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <select
                    id="event_location"
                    value={values.event_location}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="event_location"
                    autoComplete="event-location"
                    className="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                  >
                    <option></option>
                    {locations && locations.length > 0 && locations.map((location) => {
                      return (
                        <option value={location.name} id={location.id.toString()}>{location.name}</option>
                      )
                    })}
                  </select>
                </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="efs_staff" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                EFS Staff
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  type="text"
                  value={values.efs_staff}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="efs_staff"
                  id="efs_staff"
                  autoComplete="efs-staff"
                  className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                />
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="number_of_people" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                # of people
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
              <input
                    name="number_of_people"
                    id="number_of_people"
                    autoComplete="number-of-people"
                    type="number"
                    onChange={v => {
                      setFieldValue('number_of_people', v.target.value);
                    }}
                    onBlur={handleBlur}
                    defaultValue={initialValues['number_of_people']}
                  />
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="director" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Director
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  type="text"
                  value={values.director}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="director"
                  id="director"
                  autoComplete="director"
                  className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                />
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="scheduled_arrival_time" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Scheduled Arrival Time
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <select
                      id="scheduled_arrival_time"
                      value={values.scheduled_arrival_time}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="scheduled_arrival_time"
                      autoComplete="scheduled_arrival_time"
                      className="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                    >
                      <option></option>
                      {businessHours && businessHours.length > 0 && businessHours.map((location) => {
                        return (
                          <option value={location.name} id={location.id.toString()}>{location.name}</option>
                        )
                      })}
                  </select>
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="scheduled_end_time" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Scheduled End Time
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <select
                  id="scheduled_end_time"
                  value={values.scheduled_end_time}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="scheduled_end_time"
                  autoComplete="scheduled_end_time"
                  className="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                >
                  <option></option>
                  {businessHours && businessHours.length > 0 && businessHours.map((location) => {
                    return (
                      <option value={location.name} id={location.id.toString()}>{location.name}</option>
                    )
                  })}
                  </select>
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="salesperson" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Salesperson
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <select
                  id="salesperson"
                  value={values.salesperson}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="salesperson"
                  autoComplete="salesperson"
                  className="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                >
                  <option></option>
                  {sales && sales.length > 0 && sales.map((sale) => {
                    return (
                      <option value={sale.id} id={sale.id}>{sale.first_name} {sale.last_name}</option>
                    )
                  })}
                </select>
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label htmlFor="captain" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                  Assign to
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <select
                    id="captain"
                    name="captain"
                    autoComplete="captain"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.captain}
                    className="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                  >
                    <option></option>
                    {captains && captains.map((captain) => {
                      return (
                        <option key={captain.uuid} value={captain.id}>{captain.first_name} {captain.last_name}</option>
                      )
                    })}
                  </select>
                  {
                    errors.captain ?
                    <p className="mt-2 text-sm text-red-600" id="event-name-error">
                      Captain is required.
                    </p>
                  : null
                  }
                </div>
              </div>
          </div>
        </div>
      </div>

      <div className="pt-5">
        <div className="flex justify-end">
          <Link to="/reports">
            <button
              type="button"
              className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Cancel
            </button>
          </Link>
          <button
            type="submit"
            //onClick={() => {submit(values)}}
            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Save
          </button>
        </div>
      </div>
    </form>
    )
  }
  