const locations = [
    {
        id: 0,
        name: 'Rivermead'
    },
    {
        id: 1,
        name: 'Riverside'
    },
    {
        id: 2,
        name: 'Oaklawn'
    },
    {
        id: 3,
        name: 'Oaklawn Lodge'
    },
    {
        id: 4,
        name: 'Mandarin'
    },
    {
        id: 5,
        name: 'Beaches'
    },
    {
        id: 6,
        name: 'Chapel Hill'
    },
    {
        id: 7,
        name: 'Greenlawn'
    },
    {
        id: 8,
        name: 'Holly Hill'
    }
]

export default locations;
