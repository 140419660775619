import React, { useState, useEffect, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import { instance } from '../../../../utils/axios';
import { listUploadsByDirectory, getContentsById } from '../../../../utils/s3';
import initialValuesHG from '../../Create/HG/initialValues';
import getData from '../../../../utils/hooks';
import initialValuesStandard from '../../Create/Standard/initialValues';
import dayjs from 'dayjs';

export default function PastDetail() {
  const axios = instance;
  const uploadList = [];
  const history = useHistory();
  const [ error, setError ] = useState('');
  const [ captains, setCaptains ] = useState([]);
  const [ upload, setUploads ] = useState([]);
  const [ reports, setReports ] = useState({
    ...initialValuesHG,
    ...initialValuesStandard,
    report_name: '',
    event_datetime: '',
    drop_off_location: '',
    arrival_time: '',
    expected_time: '',
    contact: '',
    signature: '',
    captain: ''
  });
  const split_path = window.location.pathname.split('/');
  let reporttype = split_path[4];
  const guuid = split_path[5];
  const [ sales, setSales ] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getData(`${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOSTNAME}:${process.env.REACT_APP_API_PORT}/api/user/classify`, setSales, setError, {
        role: "Sales"
      });
    getData(`${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOSTNAME}:${process.env.REACT_APP_API_PORT}/api/user/classify`, setCaptains, setError);
  }, [])

  useEffect(() => {
    let uploadTmp = [...upload];
    listUploadsByDirectory(guuid).then((uploads) => {
      if (uploads?.Contents) {
        for (let index = 0; index < uploads.Contents.length; index++) {
          const element = uploads.Contents[index];
          getContentsById(element.Key).then((obj) => {
            uploadTmp.push(obj)
          })
        }
      }
    }).finally(() => {
      setUploads(uploadTmp)
      setLoading(false)
    })
  }, [reporttype, guuid, axios, reports.captain])

    useEffect(() => {
      if (reporttype === 'hg-order-form') {
        reporttype = 'hgform'
      } else if (reporttype === 'standard-efs-form') {
        reporttype = 'standard'
      }
      const url = `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOSTNAME}:${process.env.REACT_APP_API_PORT}/api/${reporttype}/${guuid}`
      axios.get(url).then((data) => {
        setReports(data.data)
      })
    }, [axios, guuid, reporttype, upload])

  if (reports.captain === '') {
    return <div>Loading...</div>
  }

  const eventTitle = reporttype.toLowerCase().replace(/\b(\w)/g, s => s.toUpperCase());

  const bodyForm = () => {
    if (!reports || loading) {
      return (
        <div>Loading...</div>
      )
    } else {
      return (
        Object.entries(reports).map((item, val) => {
          if (item[0] === 'id' || item[0] === 'tenant' || item[0] === 'uuid' || item[0] === 'signature' || item[0] === 'query' || item[0] === 'fields' || item[0] === 'values') {
            return null;
          }
          let title;
          const captain = captains.find( ({ id }) => id === item[1]);
          if (item[0] === 'captain') {
            item[1] = captain?.first_name;
          }
          if (item[0] === 'assigned_to') {
            item[1] = captain?.first_name;
          }
          if (item[0] === 'salesperson') {
            const tmp = item[1];
            item[1] = sales.length > 0 && sales.filter((person) => person.id === tmp)[0]?.first_name
          }

          if (item[1] === true) {
            item[1] = 'Yes'
          } else if (item[1] === false) {
            item[1] = 'No'
          } else if (item[1] === '') {
            item[1] = 'No Response'
          }

          if (item[0] === 'event_datetime' || item[0] === 'createdAt' || item[0] === 'updatedAt') {
            const p = dayjs(item[1].toString(), 'YYYY-MM-DD')
            item[1] = p.format('ddd MMM DD YYYY hh:mm A')
          }

          if (item[0] === 'uploads') {
            item['upload'] = () => {
              console.log('mapping uploads ', upload);
              
              return (
                <>
                { upload?.length > 0 && upload.map((up) => {
                  console.log('up ', up)
                return (
                <div><a href={up}>Click to view</a></div>) })}
                </>
              )
            }
          }

          switch (item[0]) {
            case 'uuid':
              title = 'Identifier'
              break;
            case 'set_side_for_bg':
              title = 'Set food aside for bride and groom?'
              break;
            case 'planner':
              title = 'Planner'
              break;
            case 'type':
              title = 'Report Type'
              break;
            case 'delivery_issues':
              title = 'Were there delivery issues?';
              break;
            case 'expected_time':
              title = 'Expected Time'
              break;
            case 'arrival_time':
              title = 'Arrival time';
              break;
            case 'delivery_issues_reason':
              title = 'What happened with the delivery issues?';
              break;
            case 'contact_name':
              title = 'Contact Name';
              break;
            case 'comments':
              title = 'Comments';
              break;
            case 'delivery_drop_off_location':
              title = 'Delivery drop off location?';
              break;
            case 'is_delivery':
              title = 'Delivery Event?';
              break;
            case 'clients_on_time':
              title = 'Were the clients on time?';
              break;
            case 'delivery_on_time':
              title = 'Was the delivery on time?';
              break;
            case 'incidents_occurred_reason':
              title = 'What happened at the event?';
              break;
            case 'incidents_occurred':
              title = 'Did any incidents occur at the event?';
              break;
            case 'equipment_needed':
              title = 'Was equipment needed?';
              break;
            case 'equipment_needed_reason':
              title = 'What happened?';
              break;
            case 'clean_venue_afterwards':
              title = 'Did we EFS leave the venue clean?';
              break;
            case 'clean_venue_afterwards_reason':
              title = 'What happened?';
              break;
            case 'equipment_needed_reason':
              title = 'What equipment was needed?';
              break;
            case 'outside_food':
              title = 'Outside food brought in?';
              break;
            case 'outside_food_reason':
              title = 'Why?';
              break;
            case 'outside_alcohol':
              title = 'Outside alcohol brought in?';
              break;
            case 'outside_alcohol_reason':
              title = 'Why?';
              break;
            case 'director':
              title = 'Director';
              break;
            case 'extra_food_boxed_reason':
              title = 'Why wasn\'t the extra food boxed?'
              break;
            case 'extra_food_boxed':
              title = 'Was the extra food boxed?'
              break;
            case 'extra_food_boxed_reason':
              title = 'Why not?';
              break;
            case 'enough_food':
              title = 'Was there enough food?'
              break;
            case 'enough_food_reason':
              title = 'What happened?';
              break;
            case 'number_of_people_accurate':
              title = 'Number of People Accurate'
              break;
            case 'number_of_people_accurate_reason':
              title = 'Number of People Accurate Reason'
              break;
            case 'event_started_on_time_reason':
              title = 'Event Started On Time Reason'
              break;
            case 'scheduled_arrival_time':
              title = 'Scheduled Arrival Time';
              break;
            case 'scheduled_end_time':
              title = 'Scheduled End Time';
              break;
            case 'status':
              title = 'Report Status'
              break;
            case 'report_name':
              title = 'Report Name'
              break;
            case 'event_started_on_time':
              title = 'Event Started On Time';
              break;
            case 'event_setup_on_time':
              title = 'Event setup on time';
              break;
            case 'event_setup_on_time_reason':
              title = 'Why wasn\'t the event setup on time?';
              break;
            case 'cocktail_hour_on_time':
              title = 'Cocktail hour on time?';
              break;
            case 'cocktail_hour_on_time_reason':
              title = 'What time did it start and why?';
              break;
            case 'room_setup_in_time':
              title = 'Room setup in time?';
              break;
            case 'room_setup_in_time_reason':
              title = 'Why wasn\'t the room setup in time?';
              break;
            case 'vendors_served':
              title = 'Vendors were served?';
              break;
            case 'vendors_served_reason':
              title = 'Why not?';
              break;
            case 'bg_served':
              title = 'Bride and groom were served?';
              break;
            case 'bg_served_reason':
              title = 'Why not?';
              break;
            case 'set_aside_for_bg':
              title = 'Food set aside for bride and groom?';
              break;
            case 'set_aside_for_bg_reason':
              title = 'Why not?';
              break;
            case 'diet_restrictions_served':
              title = 'Diet restrictions were served?';
              break;
            case 'diet_restrictions_served_reason':
              title = 'Why not?';
              break;
            case 'extra_food_boxed_bg':
              title = 'Extra food boxed for the bride and groom?';
              break;
            case 'extra_food_boxed_bg_reason':
              title = 'Why not?';
              break;
            case 'dinner_on_time':
              title = 'Did dinner start on time?';
              break;
            case 'dinner_on_time_reason':
              title = 'Why wasn\'t dinner started on time?';
              break;
            case 'number_of_people_served_dinner':
              title = 'Number of people served dinner';
              break;
            case 'cake_topper_kept':
              title = 'Cake topper kept?';
              break;
            case 'cake_topper_kept_reason':
              title = 'Why?';
              break;
            case 'cake_boxed_bg':
              title = 'Cake boxed for bride and groom?';
              break;
            case 'cake_boxed_bg_reason':
              title = 'Why not?';
              break;
            case 'food_given_to_planner':
              title = 'Food given to planner?';
              break;
            case 'food_given_to_planner_reason':
              title = 'Why not?';
              break;
            case 'equipment_kitchen_clean':
              title = 'Equipment and kitchen clean?';
              break;
            case 'equipment_kitchen_clean_reason':
              title = 'Why?';
              break;
            case 'check_out_on_time':
              title = 'Checked out on time?';
              break;
            case 'checked_out_with':
              title = 'Checked out with?';
              break;
            case 'event_datetime':
              title = 'Event Date'
              break;
            case 'event_summary':
              title = 'Event Summary';
              break;
            case 'beo_number':
              title = 'BEO Number';
              break;
            case 'number_of_people':
              title = 'Number of people';
              break;
            case 'efs_staff':
              title = 'EFS Staff';
              break;
            case 'event_location':
              title = 'Event Location';
              break;
            case 'drop_off_location':
              title = 'Drop off location'
              break;
            case 'arrival_datetime':
              title = 'Arrival Time'
              break;
            case 'expected_datetime':
              title = 'Expected Time'
              break;
            case 'contact':
              title = 'Contact Person'
              break;
            case 'assigned_to':
              title = 'Assigned To'
              break;
            case 'salesperson':
              title = 'Sales Person'
              break;
            case 'signature':
              title = 'Signature'
              break;
            case 'uploads':
              title = 'Uploads'
              break;
            case 'created_by':
              title = 'Created By'
              break;
            case 'captain':
              title = 'Captain'
              break;
            case 'createdAt':
              title = 'Created At'
              break;
            case 'updatedAt':
              title = 'Submitted At'
              break;
            default:
              if (item[0]) {
                // do something to inspect the _why not
              }
              title = 'Why not?';
              break;
          }
          
          return (
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="report_name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                {title}
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <div
                  id="report_name"
                  className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                >
                  {item[0] !== 'uploads' ? item[1] : null}
                  {item[0] === 'uploads' ? item['upload']() : null}
                </div>
              </div>
            </div>
          )
        })
      )
    }
  }

  return (
      <div className="space-y-8 divide-y divide-gray-200 mb-8">
        <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
          <div className="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
            <div>
              <h3 className="text-lg leading-6 font-medium text-gray-900">{eventTitle} Event</h3>
            </div>
            <div className="space-y-6 sm:space-y-5">
                {bodyForm()}
              </div>
            </div>
          </div>

        <div className="pt-5">
          <div className="flex justify-end">
            <Link to="/reports/past">
              <button
                type="button"
                className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Back
              </button>
            </Link>
          </div>
        </div>
      </div>
  )
}
