const initialValues = {
      beo_number: '',
      event_datetime: '',
      event_location: '',
      efs_staff: '',
      number_of_people: null,
      director: '',
      salesperson: '',
      status: '',
      scheduled_arrival_time: '',
      scheduled_end_time: '',
      type: '',
      captain: '',
      assigned_to: '',
      event_setup_on_time: false,
      event_setup_on_time_reason: '',
      number_of_people_accurate: false,
      number_of_people_accurate_reason: '',
      enough_food: false,
      enough_food_reason: '',
      extra_food_boxed: false,
      extra_food_boxed_reason: '',
      outside_food: false,
      outside_food_reason: '',
      outside_alcohol: false,
      outside_alcohol_reason: '',
      equipment_needed: false,
      equipment_needed_reason: '',
      clean_venue_afterwards: false,
      clean_venue_afterwards_reason: '',
      incidents_occurred: false,
      incidents_occurred_reason: '',
      delivery_on_time: false,
      delivery_on_time_reason: '',
      clients_on_time: false,
      clients_on_time_reason: '',
      is_delivery: false,
      delivery_drop_off_location: '',
      delivery_issues: false,
      delivery_issues_reason: '',
      contact_name: '',
      comments: '',
      query: '',
      fields: '',
      values: ''
}

export default initialValues;
