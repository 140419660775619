const initialValues = {
      beo_number: '',
      event_datetime: '',
      event_location: '',
      planner: '',
      salesperson: '',
      number_of_people: '',
      status: '',
      arrival_time: '',
      scheduled_end_time: '',
      type: '',
      captain: '',
      assigned_to: '',
      event_setup_on_time: false,
      event_setup_on_time_reason: '',
      cocktail_hour_on_time: false,
      cocktail_hour_on_time_reason: '',
      set_side_for_bg: false,
      room_setup_in_time: false,
      room_setup_in_time_reason: '',
      vendors_served: false,
      bg_served: false,
      diet_restrictions_served: false,
      extra_food_boxed_bg: false,
      dinner_on_time: false,
      dinner_on_time_reason: '',
      number_of_people_served_dinner: 0,
      cake_topper_kept: false,
      cake_boxed_bg: false,
      food_given_to_planner: false,
      equipment_kitchen_clean: false,
      check_out_on_time: false,
      checked_out_with: false,
      event_summary: ''
}

export default initialValues;
