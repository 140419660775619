import dayjs from 'dayjs';
import jwtDecode from 'jwt-decode';
import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { instance } from '../../../utils/axios';
import getData from '../../../utils/hooks';

  export default function ActiveReports() {
    const axios = instance;
    const [ reports, setReports ] = useState([]);
    const history = useHistory();
    const [ captains, setCaptains ] = useState([]);
    const [ errors, setError] = useState([])
    const [sales, setSales] = useState([]);
    
    useEffect(() => {
      getData(`${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOSTNAME}:${process.env.REACT_APP_API_PORT}/api/user/classify`, setSales, setError, {
        role: "Sales"
      });
      getData(`${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOSTNAME}:${process.env.REACT_APP_API_PORT}/api/user/classify`, setCaptains, setError);
    }, [])

    const onClickHandler = (report) => {
      history.push(`/reports/view/${report?.type?.toLowerCase().replace(/\s/g, '-')}/${report?.uuid}`)
    }
    
    useEffect(() => {
      if (!localStorage.getItem('access_token')) {
        return;
      }
      const token = localStorage.getItem('access_token').slice(7);
      
      let decoded;
      try {
        decoded = jwtDecode(token);
        
      } catch (e) {
        return ;
      }
      
      let config;
      
      if (decoded?.aud[0] === 'Admin') {
        config = {
          where: {
            status: "Active"
          }
        }
      } else {
          config = {
            where: {
              status: "Active",
              captain: decoded.id
            }
          }
        }
      Promise.all([
        axios.post(`${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOSTNAME}:${process.env.REACT_APP_API_PORT}/api/hgform/search`, config),
        axios.post(`${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOSTNAME}:${process.env.REACT_APP_API_PORT}/api/dropoff/search`, config),
        axios.post(`${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOSTNAME}:${process.env.REACT_APP_API_PORT}/api/standard/search`, config)
      ]).then((data) => {
        const combined = [];
        data.forEach((report) => {
          combined.push(...report.data);
        })
        setReports(combined);
      })
    }, [])

    return (
      <div className="flex flex-col">
        <div className="-my-2 overflow-x-auto sm:-mx-3 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Event Name
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Event Date
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Event Captain
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Event Type
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Event Status
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Sales
                    </th>
                    <th scope="col" className="relative px-6 py-3">
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {reports.map((report) => (
                    <tr key={report?.uuid} className="cursor-pointer" onClick={() => {onClickHandler(report)}}>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="flex items-center">
                          <div className="ml-4">
                            <div className="text-sm font-medium text-gray-900">{report?.report_name ? report?.report_name : report?.event_location}</div>
                            <div className="text-sm text-gray-500">{report?.event_location}</div>
                          </div>
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-900">{report.event_datetime && dayjs(report.event_datetime.toString(), 'YYYY-MM-DD HH:MM:SS').format('ddd MMM DD YYYY hh:mm A')}</div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-900">{captains && captains.length > 0 && captains.filter((cap) => cap.id === report?.captain).length > 0 && captains.filter((cap) => cap.id === report?.captain)[0].first_name} </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-900">{report?.type}</div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                          {report?.status}
                        </span>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{sales && sales.length > 0 ? sales.filter((cap) => cap.id === report?.salesperson)[0]?.first_name || 'Not Found' : null}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                        <Link to={`/reports/view/${report?.type?.toLowerCase().replace(/\s/g, '-')}/${report?.uuid}`}>
                          <a href="#" className="text-indigo-600 hover:text-indigo-900">
                            Edit
                          </a>
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    )
  }
  