const locations = [
    {
        id: 0,
        name: 'Assembly Room'
    },
    {
        id: 1,
        name: 'Bowing Oaks'
    },
    {
        id: 2,
        name: 'Clay Theatre'
    },
    {
        id: 3,
        name: 'Courtyard'
    },
    {
        id: 4,
        name: 'Cottonwood'
    },
    {
        id: 5,
        name: 'Hamton Inn'
    },
    {
        id: 6,
        name: 'Hilton Garden Inn'
    },
    {
        id: 7,
        name: 'Lightner'
    },
    {
        id: 8,
        name: 'Manor at 12 Oaks'
    },
    {
        id: 9,
        name: 'Nocatee'
    },
    {
        id: 10,
        name: 'Riverhouse'
    },
    {
        id: 11,
        name: 'National Guard'
    },
    {
        id: 12,
        name: 'Scottish Rite'
    },
    {
        id: 13,
        name: 'The Oldest House'
    },
    {
        id: 14,
        name: 'Treasury'
    }
]

export default locations;
