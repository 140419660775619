const initialToggleValues = {
    event_setup_on_time: false,
    cocktail_hour_on_time: false,
    set_side_for_bg: false,
    room_setup_in_time: false,
    vendors_served: false,
    bg_served: false,
    diet_restrictions_served: false,
    extra_food_boxed_bg: false,
    dinner_on_time: false,
    cake_topper_kept: false,
    cake_boxed_bg: false,
    food_given_to_planner: false,
    equipment_kitchen_clean: false,
    check_out_on_time: false,
    checked_out_with: false
}

export default initialToggleValues;
