import React from 'react';
import { Link } from 'react-router-dom';
/* This example requires Tailwind CSS v2.0+ */
import { MailIcon, PhoneIcon } from '@heroicons/react/solid'

const reports = [
  {
    name: 'HG',
    title: 'HG Order',
    href: 'hgform',
    description: 'Hardage Giddons',
    email: 'janecooper@example.com',
    telephone: '+1-202-555-0170'
  },
  {
    name: 'Standard',
    title: 'Standard EFS order',
    href: 'standard',
    description: 'Weddings, Community events, etc.',
    email: 'janecooper@example.com',
    telephone: '+1-202-555-0170'
  },
  {
    name: 'Dropoff',
    title: 'Drop off order',
    href: 'dropoff',
    description: 'Drop off and run',
    email: 'janecooper@example.com',
    telephone: '+1-202-555-0170'
  },
]

export default function FormTypes() {
  return (
    <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
      {reports.map((report) => (
        <li key={report.email} className="col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200">
          <Link to={`/reports/${report.href}`}>
            <a
              // href={`/reports/${report.href}`}
              className="cursor-pointer">
              <div className="w-full flex items-center justify-between p-6 space-x-6">
                <div className="flex-1 truncate">
                  <div className="flex items-center space-x-3">
                    <h3 className="text-gray-900 text-sm font-medium truncate">{report.name}</h3>
                  </div>
                  <p className="mt-1 text-gray-500 text-sm truncate">{report.title}</p>
                </div>
              </div>
            </a>
            <div>
              <div className="-mt-px flex divide-x divide-gray-200">
                <div className="w-0 flex-1 flex">
                  <a
                    href={`mailto:${report.email}`}
                    className="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500"
                  >
                    <MailIcon className="w-5 h-5 text-gray-400" aria-hidden="true" />
                    <span className="ml-3">Email</span>
                  </a>
                </div>
                <div className="-ml-px w-0 flex-1 flex">
                  <a
                    href={`tel:${report.telephone}`}
                    className="relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500"
                  >
                    <PhoneIcon className="w-5 h-5 text-gray-400" aria-hidden="true" />
                    <span className="ml-3">Call</span>
                  </a>
                </div>
              </div>
            </div>
          </Link>
        </li>
      ))}
    </ul>
  )
}
