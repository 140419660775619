import React, { useEffect, useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import SignatureCanvas from 'react-signature-canvas'
import { useFormik } from 'formik';
import { instance } from '../../../../utils/axios';
import Toggle from '../../../../components/Toggle';
import getData from '../../../../utils/hooks';
import initialValues from '../../Create/HG/initialValues';
import toggleValues from '../../Create/HG/toggleValues';
import { addUpload, addUploadByString } from '../../../../utils/s3';
import DateTimePicker from '../../../../components/DatePicker';

export default function HGForm() {
  const axios = instance;
  const [toggled, toggleButton] = useState(toggleValues);
  const history = useHistory();
  const [ error, setError ] = useState('');
  const [ captains, setCaptains ] = useState([]);
  const [ reports, setReports ] = useState({});
  const split_path = window.location.pathname.split('/');
  const guuid = split_path[4];
  const [ sales, setSales ] = useState([]);
  let sigpad = useRef<HTMLCanvasElement>();
  
  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOSTNAME}:${process.env.REACT_APP_API_PORT}/api/hgform/${guuid}`).then((data) => {
      console.log(data.data)
      setReports(data.data)
    })
  }, [])
  useEffect(() => {
    getData(`${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOSTNAME}:${process.env.REACT_APP_API_PORT}/api/user/classify`, setSales, setError, {
        role: "Sales"
      });
    getData(`${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOSTNAME}:${process.env.REACT_APP_API_PORT}/api/user/classify`, setCaptains, setError);
  }, [])
  const handleToggleButton = (name, value) => {
    toggleButton({
      ...toggled,
      [name]: !toggled[name]
    })
  }

  const submit = async (values) => {
    const combined = {...values, ...toggled}
    await addUploadByString(guuid, sigpad.current.toDataURL('image/png'))
    try {
      const formRequest = await axios.put(`${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOSTNAME}:${process.env.REACT_APP_API_PORT}/api/hgform/${guuid}`, {
        ...combined,
        status: 'Complete',
        type: "HG Order Form",
      });
      if (formRequest.status === 200) {
        history.push('/reports/past')
      }
    } catch (e) {
      setError('Failed to submit form. Please try again.')
    }
  }

  const handleUpload = async (guuid, element) => {
    const upload = await addUpload(guuid, element)
    return upload;
  }

const validate = values => {
  const errors = {
  };

  return errors;
};

const formik = useFormik({
  initialValues: reports,
  validate,
  enableReinitialize: true,
  onSubmit: (values, {setSubmitting}) => {
    submit(values);
  }})
const { errors, handleSubmit, handleChange, handleBlur, setFieldValue, values, isSubmitting } = formik;

    return (
      <form className="space-y-8 divide-y divide-gray-200 mb-8" onSubmit={handleSubmit}>
      <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
        <div className="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">HG Order</h3>
          </div>
          <div className="space-y-6 sm:space-y-5">
            <h4 className="text-lg leading-6 font-medium text-gray-900">Event Information</h4>
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="beo_number" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                BEO #
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  type="text"
                  value={values.beo_number}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="beo_number"
                  id="beo_number"
                  autoComplete="beo-number"
                  className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                />
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="event_datetime" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Event Date
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
              <DateTimePicker
                  id="event_datetime"
                  name="event_datetime"
                  value={values.event_datetime}
                  defaultVaue="event-date"
                  interval={15}
                  onChange={setFieldValue}
                />
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="event_location" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Location
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  type="text"
                  value={values.event_location}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="event_location"
                  id="event_location"
                  autoComplete="event-location"
                  className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                />
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="efs_staff" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                EFS Staff
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  type="text"
                  value={values.efs_staff}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="efs_staff"
                  id="efs_staff"
                  autoComplete="efs-staff"
                  className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                />
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="number_of_people" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                # of people
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
              <input
                    name="number_of_people"
                    id="number_of_people"
                    autoComplete="number_of_people"
                    type="number"
                    value={values.number_of_people}
                    onChange={v => {
                      setFieldValue('number_of_people', v.target.value);
                    }}
                    onBlur={handleBlur}
                  />
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="director" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Director
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  type="text"
                  value={values.director}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="director"
                  id="director"
                  autoComplete="director"
                  className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                />
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="scheduled_arrival_time" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Scheduled Arrival Time
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  type="text"
                  value={values.scheduled_arrival_time}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="scheduled_arrival_time"
                  id="scheduled_arrival_time"
                  autoComplete="scheduled-arrival-time"
                  className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                />
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="scheduled_end_time" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Schedule End Time
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  type="text"
                  value={values.scheduled_end_time}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="scheduled_end_time"
                  id="scheduled_end_time"
                  autoComplete="scheduled-end-time"
                  className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                />
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label htmlFor="captain" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                  Assign to
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <select
                    id="captain"
                    name="captain"
                    autoComplete="captain"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.captain}
                    className="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                  >
                    <option></option>
                    {captains && captains.map((captain) => {
                      return (
                        <option key={captain.uuid} value={captain.id}>{captain.first_name} {captain.last_name}</option>
                      )
                    })}
                  </select>
                  {
                    errors.captain ?
                    <p className="mt-2 text-sm text-red-600" id="event-name-error">
                      Captain is required.
                    </p>
                  : null
                  }
                </div>
              </div>

              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label htmlFor="salesperson" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                  Salesperson
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <select
                    id="salesperson"
                    value={values.salesperson}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="salesperson"
                    autoComplete="salesperson"
                    className="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                  >
                    <option></option>
                    <option></option>
                    {sales && sales.length > 0 && sales.map((sale) => {
                      return (
                        <option value={sale.id} id={sale.id}>{sale.first_name} {sale.last_name}</option>
                      )
                    })}
                  </select>
                </div>
              </div>

              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="event_started_on_time" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Did the event start on time?
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
              <Toggle
                    id="event_started_on_time"
                    onChange={v => {
                      setFieldValue('event_started_on_time', v.target.value);
                    }}
                    toggled={toggled['event_started_on_time']}
                    handleToggleButton={handleToggleButton}
                    />
                  { !toggled['event_started_on_time'] && <>
                    <span className="text-sm ml-8 font-medium text-gray-900">Why not?</span>
                    <input
                      type="text"
                      value={values.event_started_on_time_reason}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="event_started_on_time_reason"
                      id="event_started_on_time_reason"
                      autoComplete="event-started-on-time-reason"
                      className="max-w-md ml-8 w-small shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
                    />
                    </>
                  }
                </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="number_of_people_accurate" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Was the amount of people accurate?
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <Toggle
                  name="number_of_people_accurate"
                  id="number_of_people_accurate"
                  value={values.number_of_people_accurate}
                  toggled={toggled['number_of_people_accurate']}
                  handleToggleButton={handleToggleButton}
                  onChange={v => {
                    setFieldValue('number_of_people_accurate', v.target.value);
                  }}
                  />
                  { !toggled['number_of_people_accurate'] && <>
                    <span className="text-sm ml-8 font-medium text-gray-900">How many over / under the expected total?</span>
                    <input
                      type="text"
                      value={values.number_of_people_accurate_reason}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="number_of_people_accurate_reason"
                      id="number_of_people_accurate_reason"
                      autoComplete="number-of-people-accurate-reason"
                      className="max-w-md ml-8 w-small shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
                    />
                    </>
                  }
                </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="enough_food" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Was there enough food?
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <Toggle
                  name="enough_food"
                  value={values.enough_food}
                  id="enough_food"
                  onChange={v => {
                    setFieldValue('enough_food', v.target.value);
                  }}
                  toggled={toggled['enough_food']}
                  handleToggleButton={handleToggleButton}
                  />
                  { !toggled['enough_food'] && <>
                    <span className="text-sm ml-8 font-medium text-gray-900">Why not?</span>
                    <input
                      type="text"
                      value={values.enough_food_reason}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="enough_food_reason"
                      id="enough_food_reason"
                      autoComplete="enough-food-reason"
                      className="max-w-md ml-8 w-small shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
                    />
                    </>
                  }
                </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="extra_food_boxed" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Was the extra food boxed up?
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <Toggle
                  name="extra_food_boxed"
                  id="extra_food_boxed"
                  value={values.extra_food_boxed}
                  onChange={v => {
                    setFieldValue('extra_food_boxed', v.target.value);
                  }}
                  toggled={toggled['extra_food_boxed']}
                  handleToggleButton={handleToggleButton}
                  />
                  { !toggled['extra_food_boxed'] && <>
                    <span className="text-sm ml-8 font-medium text-gray-900">Why not?</span>
                    <input
                      type="text"
                      value={values.extra_food_boxed_reason}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="extra_food_boxed_reason"
                      id="extra_food_boxed_reason"
                      autoComplete="extra-food-boxed-reason"
                      className="max-w-md ml-8 w-small shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
                    />
                    </>
                  }
                </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="outside_food" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Was outside food brought into the event?
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <Toggle
                  name="outside_food"
                  onBlur={handleBlur}
                  toggled={toggled['outside_food']}
                  handleToggleButton={handleToggleButton}
                  />
                  { !toggled['outside_food'] && <>
                    <span className="text-sm ml-8 font-medium text-gray-900">Why?</span>
                    <input
                      type="text"
                      value={values.outside_food_reason}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="outside_food_reason"
                      id="outside_food_reason"
                      autoComplete="outside-food-reason"
                      className="max-w-md ml-8 w-small shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
                    />
                    </>
                  }
                </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="outside_alcohol" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Was outside alcohol brought into the event?
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <Toggle
                  id="outside_alcohol"
                  value={values.outside_alcohol}
                  name="outside_alcohol"
                  onChange={v => {
                    setFieldValue('outside_alcohol', v.target.value);
                  }}
                  toggled={toggled['outside_alcohol']}
                  handleToggleButton={handleToggleButton}
                  />
                  { !toggled['outside_alcohol'] && <>
                    <span className="text-sm ml-8 font-medium text-gray-900">Why?</span>
                    <input
                      type="text"
                      value={values.outside_alcohol_reason}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="outside_alcohol_reason"
                      id="outside_alcohol_reason"
                      autoComplete="outside-alcohol-reason"
                      className="max-w-md ml-8 w-small shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
                    />
                    </>
                  }
                </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="equipment_needed" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Did you have all the equipment needed for the event?
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <Toggle
                  id="equipment_needed"
                  name="equipment_needed"
                  value={values.equipment_needed}
                  onChange={v => {
                    setFieldValue('equipment_needed', v.target.value);
                  }}
                  toggled={toggled['equipment_needed']}
                  handleToggleButton={handleToggleButton}
                  />
                  { !toggled['equipment_needed'] && <>
                    <span className="text-sm ml-8 font-medium text-gray-900">What was missing?</span>
                    <input
                      type="text"
                      value={values.equipment_needed_reason}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="equipment_needed_reason"
                      id="equipment_needed_reason"
                      autoComplete="equipment-needed-reason"
                      className="max-w-md ml-8 w-small shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
                    />
                    </>
                  }
                </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="clean_venue_afterwards" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Did we (EFS) leave the venue as clean as we previously found it? (Attach before and after photo)
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <Toggle
                  name="clean_venue_afterwards"
                  value={values.clean_venue_afterwards}
                  id="clean_venue_afterwards"
                  onChange={v => {
                    setFieldValue('clean_venue_afterwards', v.target.value);
                  }}
                  toggled={toggled['clean_venue_afterwards']}
                  handleToggleButton={handleToggleButton}
                  />
                  { !toggled['clean_venue_afterwards'] && <>
                    <span className="text-sm ml-8 font-medium text-gray-900">Why?</span>
                    <input
                      type="text"
                      value={values.clean_venue_afterwards_reason}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="clean_venue_afterwards_reason"
                      id="clean_venue_afterwards_reason"
                      autoComplete="outside-venue-afterwards-reason"
                      className="max-w-md ml-8 w-small shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
                    />
                    </>
                  }
                </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="incidents_occurred" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Did any issues arise before, during or after the event?
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <Toggle
                  name="incidents_occurred"
                  id="incidents_occurred"
                  value={values.incidents_occurred}
                  toggled={toggled['incidents_occurred']}
                  onChange={v => {
                    setFieldValue('incidents_occurred', v.target.value);
                  }}
                  handleToggleButton={handleToggleButton}
                  />
                  { toggled['incidents_occurred'] && <>
                    <span className="text-sm ml-8 font-medium text-gray-900">Please explain what happened</span>
                    <input
                      type="text"
                      value={values.incidents_occurred_reason}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="incidents_occurred_reason"
                      id="incidents_occurred_reason"
                      autoComplete="incidents-occured-reason"
                      className="max-w-md ml-8 w-small shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
                    />
                    </>
                  }
                </div>
              </div>
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label htmlFor="uploads" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                  Uploads
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <input
                    type="file"
                    name="uploads"
                    id="uploads"
                    multiple
                    capture
                    autoComplete="uploads"
                    // onChange={handleChange}
                    // onBlur={handleBlur}
                    // value={values.uploads}
                    className="block max-w-lg w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
                <button onClick={() => {handleUpload(guuid, document.getElementById('uploads'))}}>
                  Upload file
                </button>
              </div>


            <h4 className="text-lg leading-6 font-medium text-gray-900">Delivery only</h4>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="delivery_on_time" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Was delivery on time?
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <Toggle
                  name="delivery_on_time"
                  id="delivery_on_time"
                  onChange={v => {
                    setFieldValue('delivery_on_time', v.target.value);
                  }}
                  value={values.delivery_on_time}
                  onBlur={handleBlur}
                  toggled={toggled['delivery_on_time']}
                  handleToggleButton={handleToggleButton}
                  />
                  { !toggled['delivery_on_time'] && <>
                    <span className="text-sm ml-8 font-medium text-gray-900">Why?</span>
                    <input
                      type="text"
                      value={values.delivery_on_time_reason}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="delivery_on_time_reason"
                      id="delivery_on_time_reason"
                      autoComplete="outside-food-reason"
                      className="max-w-md ml-8 w-small shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
                    />
                    </>
                  }
                </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="clients_on_time" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Were clients at Location on time?
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <Toggle
                  name="clients_on_time"
                  id="clients_on_time"
                  onChange={v => {
                    setFieldValue('clients_on_time', v.target.value);
                  }}
                  value={values.clients_on_time}
                  toggled={toggled['clients_on_time']}
                  handleToggleButton={handleToggleButton}
                  />
                  { !toggled['clients_on_time'] && <>
                    <span className="text-sm ml-8 font-medium text-gray-900">Why?</span>
                    <input
                      type="text"
                      value={values.clients_on_time_reason}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="clients_on_time_reason"
                      id="clients_on_time_reason"
                      autoComplete="clients-on-time-reason"
                      className="max-w-md ml-8 w-small shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
                    />
                    </>
                  }
                </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="delivery_drop_off_location" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Address of drop off site
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  type="text"
                  value={values.delivery_drop_off_location}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="delivery_drop_off_location"
                  id="delivery_drop_off_location"
                  autoComplete="delivery-drop-off-location"
                  className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                />
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="delivery_issues" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Did any issues arise before, during or after the delivery?
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <Toggle
                  name="delivery_issues"
                  onChange={v => {
                    setFieldValue('delivery_issues', v.target.value);
                  }}
                  value={values.delivery_issues}
                  id="delivery_issues"
                  toggled={toggled['delivery_issues']}
                  handleToggleButton={handleToggleButton}
                  />
                  { toggled['delivery_issues'] && <>
                    <span className="text-sm ml-8 font-medium text-gray-900">Please explain what happened</span>
                    <input
                      type="text"
                      value={values.delivery_issues_reason}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="delivery_issues_reason"
                      id="delivery_issues_reason"
                      autoComplete="deliver-issues-reason"
                      className="max-w-md ml-8 w-small shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
                    />
                    </>
                  }
                </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="contact_name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Contact Name
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  type="text"
                  value={values.contact_name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="contact_name"
                  id="contact_name"
                  autoComplete="contact-name"
                  className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                />
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="comments" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Comments
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <textarea
                  rows={8}
                  value={values.comments}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="comments"
                  id="comments"
                >
                </textarea>
              </div>
            </div>
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="client-signature" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Client Signature
              </label>
              <SignatureCanvas
                penColor='green'
                ref={sigpad}
                canvasProps={{width: 500, height: 200, className: 'sigCanvas bg-white'}} />
            </div>
          </div>
        </div>
      </div>

      <div className="pt-5">
        <div className="flex justify-end">
          <Link to="/reports">
            <button
              type="button"
              className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Cancel
            </button>
          </Link>
          <button
            type="submit"
            onClick={() => {submit(values)}}
            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Save
          </button>
        </div>
      </div>
    </form>
    )
  }
  